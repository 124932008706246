import { useStaticQuery, graphql } from 'gatsby';

export const usePageLegalData = () => {
  const data = useStaticQuery(graphql`
    query PageLegal {
      wpPage(id: { eq: "cG9zdDoxNTEx" }) {
        id
        TalxisLegacyFiles {
          file {
            lang
            name
            file {
              title
              mediaItemUrl
            }
          }
          pageName
        }
      }
    }
  `);

  return data.wpPage;
};
