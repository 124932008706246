import LayoutProductPage from '../../components/layouts/ProductPage';
import * as style from '../../styles/pages/productIndex.module.scss';
import * as dstyle from '../../styles/pages/download.module.scss';
import * as gstyle from '../../styles/styles.module.scss';
import Footer from '../../components/Footer';
import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { ProductPageMetadata } from '../../data/page-product.data';
import { pageLegalData } from '../../data/page-legal.data';
export default function index() {
  const data = pageLegalData();
  const product = ProductPageMetadata();
  return (
    <section>
      <Helmet title="TALXIS Ke stažení"> </Helmet>
      <LayoutProductPage
        backLink="/"
        header="Zásady ochrany osobních údajů / Legacy Policy"
        subHeader=""
        color="blue"
        copy=" "
      >
        <section className={style.productList}>
          <section className={classnames(style.product)}>
            <div className={classnames(gstyle.center, style.center)}>
              <div className={classnames(dstyle.image, style.image, dstyle.legal)}></div>
              <div className={style.copy}>
                <h1>Soubory / Files</h1>
                {data.TalxisLegacyFiles.file.map((file: any, key: number) => (
                  <a
                    target="_blank"
                    key={key}
                    href={file?.file.mediaItemUrl}
                    className={classnames(dstyle.downloadButton, dstyle.fullWidth)}
                  >
                    {file.name}
                  </a>
                ))}
              </div>
            </div>
          </section>
        </section>
      </LayoutProductPage>
      <section className={style.footerSection}>
        <Footer></Footer>
      </section>
    </section>
  );
}
